<template>
  <v-dialog
      v-model="dialog"
      scrollable  
      max-width="900px"
      persistent
  >
  <v-card>
    <v-toolbar color="grey" dark class="elevation-0">
        Informacion del {{this.idRegistroCurso!=-1?'Registro de':''}} Curso
        <v-spacer></v-spacer>
        <v-btn icon color="white" @click="$emit('cerrar')">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card-text>
        <v-layout row wrap>
            <v-flex v-if="curso!=null" lg12 xs12 order-lg1 order-xs2>
                 <info-curso :curso="curso" ></info-curso>
            </v-flex>
            <v-flex lg12 xs12 order-lg2 order-xs1 v-if="curso">
                 <info-registro-curso v-if="registroCurso!=null" :registroCurso="registroCurso"></info-registro-curso>
                 <registro-curso v-else-if="validarTiempo(curso.fechaEvento,curso.horaInicio)" :idCurso="idCurso" @creado="actualizarInfoRegistro"></registro-curso>
                 <v-alert type="error" v-else>Fuera de Plazo para el registro</v-alert>
            </v-flex>           
        </v-layout>
    </v-card-text>  
    
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="$emit('cerrar')" text>cerrar</v-btn>
    </v-card-actions>
  </v-card>  
  </v-dialog>
</template>
<script>
export default {
    props:{
        dialog:{
            type: Boolean,
            default: false
        },
        idCurso: {
            type: Number,
            default: -1
        },
        idRegistroCurso: {
            type: Number,
            default: -1
        },
        modelo:Object
    },
    components:{
        'registro-curso':()=>import("@/views/Curso/NuevoRegistroCurso"),
        'info-registro-curso':()=>import("@/components/InfoRegistroCurso"),
        'info-curso':()=>import("@/components/InfoCurso.vue")
    },
    data(){
        return{
            curso:null,
            registroCurso:null
        }
    },
    mounted(){
        this.inicioModelo()
    },
    methods:{
        inicioModelo(){
            if(this.idCurso==-1){
                 this.registroCurso=this.modelo
                 this.getInfoCursoOfRegistroCurso(this.idRegistroCurso)
            }else{
                this.curso= this.modelo
                var registroCursoFind= this.$store.getters['curso/getRegistroCursoByCurso'](this.curso.id)
                if( registroCursoFind.length){
                    this.registroCurso=registroCursoFind[0]
                }
            }  
            console.log(this.curso),
            console.log(this.registroCurso)
        },
        getInfoCursoOfRegistroCurso(id){
            this.$http.get(`${this.$apiUrl}abogado-ciudadano/registro-curso/${id}/curso`)
            .then(response=>{
                this.curso=response.data.data[0]||response.data.data
            })
        },
        actualizarInfoRegistro(data){
            this.registroCurso=data
        },
        validarTiempo(date, hora) {
          var hoy = new Date();
          const [dates, hours] = date.split(" ");
          var fechaFormulario = new Date(dates + " " + hora);
          if (hoy <= fechaFormulario) {
            return true;
          } else {
            return false;
          }
        }
    }
}
</script>